<template>
<Transition name="slide-fade" appear>
	<div class="px-2 sm:px-6 lg:px-4 text-t3">
		<div class="justify-between flex items-center">
			<h2 class="text-2xl font-bold sm:text-3xl">
				Users
			</h2>

			
		</div>
		<div>
			<!-- Start Tabs Component -->
			<app-tabs :tabList="tabList">
				<template v-slot:tabPanel-1>
					<div class="rounded">
						<div class="flex w-full pt-2 items-center">
							<div class="my-3 xl:w-96 flex w-full justify-between">
								<div class="input-group relative flex flex-wrap items-stretch w-full my-3 rounded">
									<input type="search" v-model="searchValue" @input="debCheck()" class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
									<span class="input-group-text flex items-center px-3 py-1.5 text-base font-normal text-gray-700 text-center whitespace-nowrap rounded" id="basic-addon2">
										<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
											<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
										</svg>
								</span>
							</div>
						</div>
						
						<div class="flex justify-end ml-auto py-4 px-6 gap-4 items-center">
						<div class="mx-4">
							<label for="rowscount" class="inline-block mx-2 text-xs 2xl:text-base ">Number of records:</label>
							<select @change="refreshPage()" v-model="numberOfPages" id="rowscount" class="form-control w-12 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"> 
										<option value="3">3</option>
										<option value="10">10</option>
										<option value="25">25</option>
										<option value="50">50</option>
										<option value="100">100</option>
							</select>
						</div>
						<button @click="firstPage()" v-if="page>1" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
							<div class="flex">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
								</svg>
							</div>
						</button>
						<button @click="previousPage()" v-if="page>1" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
							</svg>
						</button>
						<p class="text-t3 font-medium text-xs 2xl:text-base" v-if="pageCount">Page {{page}} of {{pageCount}}</p>
						<button @click="nextPage()" v-if="isNext" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
							</svg>
						</button>
						<button @click="lastPage()" v-if="isNext" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
							</svg>
						</button>
					</div>
						<router-link :to="{name:'AddUser'}" class=" font-medium bg-t3 py-4 px-4 text-white rounded-md text-xs 2xl:text-base transition duration-400  hover:bg-t3-secondary mr-4">
	+ New </router-link>
						</div>
						<!-- Start Datatable -->
						<table class="min-w-full">
                        <thead class="border-b">
                            <tr>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left w-2/12 ">ID</th>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left w-4/12">Customer Details</th>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left w-2/12">Nationality</th>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left w-2/12">Mobile</th>
                            <th scope="col" class="text-base font-semibold text-t3 px-3 py-4 text-left w-2/12"></th>
                            </tr>
                        </thead>
                        <tbody>
							
                            <tr v-for="customer in customerOnly" :key="customer.id" class="border-b">
                            <td class=" text-gray-900  px-3 py-2 whitespace-nowrap">{{customer.customer_id}}</td>
                            <td class="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
							<router-link :to="{name: 'UserDetails', params: {id: customer.id}}">
								<div class="flex items-center text-md py-3" width="30%">
										<div class="relative w-10 h-10 mr-3 rounded-full">
											<img class="object-cover w-full h-full rounded-full" :src="customer.profile_img" alt=""
												loading="lazy" />
											<div class="absolute inset-0 rounded-full shadow-inner" aria-hidden="true">
											</div>
										</div>
										<div>
											<p class="font-semibold">{{customer.full_name}}</p>
											<p class="text-xs text-gray-600">{{customer.email}}</p>
										</div>
									</div>
							</router-link> 
                            </td>
                            <td class="text-gray-900  px-3 py-2 whitespace-nowrap">
                            <p>{{customer.nationality}}</p>
                            </td>
                            <td class="text-gray-900 px-3 py-2 whitespace-nowrap">
								{{customer.phone_number}}
							</td>
                            <td  class=" px-3 py-2 text center whitespace-nowrap">
								<div v-if="!customer.customerprofile" class="dropdown relative" width="50%" >
									<p class="text-gray-400 text-sm">Profile not configured. </p>
								</div>
								<div v-if="customer.customerprofile" class="dropdown relative" width="50%" >
									<button 
										class="dropdown-toggle px-6 py-2.5  text-t3 font-medium text-xs leading-tight uppercase hover:cursor-pointer focus:outline-none focus:ring-0  transition duration-150 ease-in-out flex items-center whitespace-nowrap"
										type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
										aria-expanded="false">
										<svg aria-hidden="true" focusable="false" data-prefix="fas"
											data-icon="caret-down" class="w-2 ml-2" role="img"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
											<path fill="currentColor"
												d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z">
											</path>
										</svg>
									</button>
									<ul class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-t3 mt-1 hidden m-0 bg-clip-padding border-none"
										aria-labelledby="dropdownMenuButton1">
										<li>
											<router-link :to="{name: 'BookNewUser', params:{user:customer.id}}" class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
												>Add Assessment</router-link>
										</li>
									</ul>
								</div>
							</td>
                            </tr>
                            
                        </tbody>
                        </table>
					<div  v-if="customerOnly.length === 0 && !loading" >		
							<div class="text-xl w-full font-semibold text-center text-t3 my-24" >
                            <img src="../assets/no-reg-users.svg" alt="" class="w-32 m-auto">
                            <p>It appears you have encountered an error!! It appears there are no users here yet.</p>
                        </div>
						</div>
					<div  v-if="customerOnly.length === 0 && loading" >
					<div class="text-xl w-full font-semibold text-center text-t3 my-24" >
                            <img src="../assets/no-reg-users.svg" alt="" class="w-32 m-auto">
                            <p>Loading. Please Hold.</p>
                            <PulseLoader :loading="loading"  color="#00ADEE" size="16px" class="mb-6"></PulseLoader>
                    </div>
					</div>

							
					</div>

				</template>
				<template v-slot:tabPanel-2>
					<div class="rounded overflow-x-scroll">
						<div class="flex justify-start pt-2">
							<div class="mb-3 xl:w-96">
								<div class="input-group relative flex flex-wrap items-stretch w-full mb-2 ml-1 rounded">
								<input type="search" v-model="searchValue" class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
								<span class="input-group-text flex items-center px-3 py-1.5 text-base font-normal text-gray-700 text-center whitespace-nowrap rounded" id="basic-addon2">
									<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
									</svg>
								</span>
								</div>
							</div>
						</div>
						<!-- Start Datatable -->
						<EasyDataTable class="text-t3 text-md" :headers="AssessorHeaders" :search-value="searchValue"
							:items="staffOnly" :rows-per-page="10" row-hover-background-color="#E8F0FD" row-border-color="#E8F0FD"
							row-font-color="#0E2D5B" row-hover-font-color="#0E2D5B" :empty-message="emptyState"
							table-font-size="16" header-font-color='#0E2D5B'>
							<template #loading>
								<img
								src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
								style="width: 100px; height: 80px;"
								/>
							</template>
							<template #full_name="{full_name, email, profile_img}">
								<!-- <router-link :to="{name: 'UserDetails', params: {id: id , type:is_customer}}"> -->
									<div class="flex items-center text-md py-4" width="30%">
										<div class="relative w-10 h-10 mr-3 rounded-full">
											<img class="object-cover w-full h-full rounded-full" :src="profile_img" alt=""
												loading="lazy" />
											<div class="absolute inset-0 rounded-full shadow-inner" aria-hidden="true">
											</div>
										</div>
										<div>
											<p class="font-semibold">{{full_name}}</p>
											<p class="text-xs text-gray-600">{{email}}</p>
										</div>
									</div>
								<!-- </router-link> -->
							</template>
							<template #actions="{id}">
								<!-- <router-link :to="{name: 'UserDetails', params: {id: id , type:is_customer}}"> -->
									<div class="flex items-center text-md py-4" width="10%">
										<span  @click="deleteStaff(id)" class="ml-5 text-t3-cancel cursor-pointer">Delete</span>
									</div>
								<!-- </router-link> -->
							</template>

						</EasyDataTable>
						<!-- End Datatable -->
					</div>

				</template>
				<template v-slot:tabPanel-3>
					<div class="rounded overflow-x-scroll">
						<div class="flex justify-start pt-2">
							<div class="mb-3 xl:w-96">
								<div class="input-group relative flex flex-wrap items-stretch w-full mb-2 ml-1 rounded">
								<input type="search" v-model="searchValue2" class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
								<span class="input-group-text flex items-center px-3 py-1.5 text-base font-normal text-gray-700 text-center whitespace-nowrap rounded" id="basic-addon2">
									<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
									</svg>
								</span>
								</div>
							</div>
						</div>
						<!-- Start Datatable -->
						<EasyDataTable class="text-t3 text-md" :headers="requestHeaders" 
							:items="enquiries" :rows-per-page="10" row-hover-background-color="#E8F0FD" row-border-color="#E8F0FD"
							row-font-color="#0E2D5B" row-hover-font-color="#0E2D5B" :empty-message="emptyState"
							table-font-size="16" header-font-color='#0E2D5B'>
							<template #loading>
								<img
								src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
								style="width: 100px; height: 80px;"
								/>
							</template>
							<template #full_name="{full_name, email, profile_img,id}">
								<router-link :to="{name: 'addReq', params: {id: id }}">
									<div class="flex items-center text-md py-4" width="30%">
										<div class="relative w-10 h-10 mr-3 rounded-full">
											<img class="object-cover w-full h-full rounded-full" :src="profile_img" alt=""
												loading="lazy" />
											<div class="absolute inset-0 rounded-full shadow-inner" aria-hidden="true">
											</div>
										</div>
										<div>
											<p class="font-semibold">{{full_name}}</p>
											<p class="text-xs text-gray-600">{{email}}</p>
										</div>
									</div>
								</router-link>
							</template>
							<template #actions="{id}">
									<div class="flex items-center text-md py-4" width="10%">
										<span  @click="$router.push({name: 'addReq', params: {id: id }})" class="ml-5 text-t3-secondary cursor-pointer">View Request</span>
									</div>
							</template>

						</EasyDataTable>
						<!-- End Datatable -->
					</div>

				</template>
				<template v-slot:tabPanel-4>
					<div class="rounded overflow-x-scroll">
						
						<!-- Start Datatable -->
						<EasyDataTable class="text-t3 text-md" :headers="AssessorHeaders" 
							:items="deletedStaff" :rows-per-page="10" row-hover-background-color="#E8F0FD" row-border-color="#E8F0FD"
							row-font-color="#0E2D5B" row-hover-font-color="#0E2D5B" :empty-message="emptyState"
							table-font-size="16" header-font-color='#0E2D5B'>
							<template #loading>
								<img
								src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
								style="width: 100px; height: 80px;"
								/>
							</template>
							<template #full_name="{full_name, email, profile_img}">
								<!-- <router-link :to="{name: 'UserDetails', params: {id: id , type:is_customer}}"> -->
									<div class="flex items-center text-md py-4" width="30%">
										<div class="relative w-10 h-10 mr-3 rounded-full">
											<img class="object-cover w-full h-full rounded-full" :src="profile_img" alt=""
												loading="lazy" />
											<div class="absolute inset-0 rounded-full shadow-inner" aria-hidden="true">
											</div>
										</div>
										<div>
											<p class="font-semibold">{{full_name}}</p>
											<p class="text-xs text-gray-600">{{email}}</p>
										</div>
									</div>
								<!-- </router-link> -->
							</template>
							<!-- <template #actions="{id}">
									<div class="flex items-center text-md py-4" width="10%">
										<span  @click="deleteStaff(id)" class="ml-5 text-t3-secondary cursor-pointer">Retrieve</span>
									</div>
							</template> -->

						</EasyDataTable>
						<!-- End Datatable -->
					</div>

				</template>
				
				
			</app-tabs>
			<!-- End Tabs Component -->
		</div>

	</div>
</Transition>
</template>

<style scoped>
	.vue3-easy-data-table[data-v-7314b30a] {
		border: none !important;
	}

</style>
<script>
	import AppTabs from '@/components/AppTabs'
	import {mapActions, mapGetters,mapState } from 'vuex'
	import _ from 'lodash';
	import Swal from 'sweetalert2';
import ReportServices from '@/services/ReportServices'
	import UsersServices from '../services/UsersServices'
	import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
	export default {
		data() {
			return {
				loading:false,
				emptyState: '',
				searchValue: '',
				page:1,
				searchValue2: '',
				numberOfPages:10,
				enquiries:[],
				tabList: [],
				booking: true,
				headers: [{
						text: "ID",
						value: "customer_id",
						sortable: true
					},
					{
						text: "Name",
						value: "full_name",
						sortable: true
					},
					{
						text: "Nationality",
						value: "nationality"
					},
					{
						text: "Mobile",
						value: "phone_number"
					},
					{
						text: "",
						value: "actions"
					},
				],
				AssessorHeaders: [
					{
						text: "Name",
						value: "full_name",
						sortable: true
					},
					{
						text: "Nationality",
						value: "nationality"
					},
					{
						text: "Mobile",
						value: "phone_number"
					},
					{
						text: "",
						value: "actions"
					},
				],
				requestHeaders: [
					{
						text: "Customer Details",
						value: "full_name",
						sortable: true
					},
				
					{
						text: "Mobile",
						value: "phone_number"
					},
					{
						text: "Preferred time",
						value: "slot_time"
					},
					{
						text: "Preferred Date",
						value: "slot_date"
					},
					{
						text: "",
						value: "actions"
					},
				],
				
			}
		},
		methods: {
			deleteStaff(id){
                Swal.fire({
							icon: "warning",
							title: "Hold",
							text: "Are you sure you want to remove this user?",
							showConfirmButton: true,
                            showDenyButton: true,
                            confirmButtonColor: '#0E2D5B',
                            denyButtonColor: '#990c53',
						}).then((result)=>{
                            if(result.value){
                            UsersServices.deleteUser(id).then(()=>{
                                Swal.fire({
                                        icon: "success",
                                        title: "Success!",
                                        text: "This user has been removed",
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: 3000,
                                    })
                                this.getstaff()
                            }).catch((error)=>{
								let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                            })
                            }
                            
                            
                        })
            },
			...mapActions({
				getcustomers: 'Users/getCustomersPage',
				getstaff: 'Users/getStaff',
				getDeletedStaff: 'Users/getDeletedStaff',
				searchcustomers: 'Users/searchCustomers',
				// getassessors: 'Users/getAllAssessors'
			}),
			debCheck: _.debounce(function(){
		
		if(this.searchValue === ""|| this.searchValue === null)
		{
			this.loading=true
		this.getcustomers({id:this.page, number:this.numberOfPages}).then(()=>{
			this.loading=false
		})
		}
		else{
			this.loading=true
			this.searchcustomers({input:this.searchValue, number:this.numberOfPages}).then(()=>{
				this.loading=false
			})
		}
         }, 700),
			nextPage(){
				this.page++
				this.loading=true
				this.getcustomers({id:this.page, number:this.numberOfPages}).then(()=>{
					this.loading=false
				})
			},
			lastPage(){
				this.page=this.pageCount
				this.loading=true
				this.getcustomers({id:this.page, number:this.numberOfPages}).then(()=>{
					this.loading=false
				})
			},
			previousPage(){
				this.page--
				this.loading=true
				this.getcustomers({id:this.page, number:this.numberOfPages}).then(()=>{
					this.loading=false
				})
			},
			firstPage(){
				this.page=1
				this.loading=true
				this.getcustomers({id:this.page, number:this.numberOfPages}).then(()=>{
					this.loading=false
				})
			},
			refreshPage(){
				this.page = 1
				this.loading=true
				this.getcustomers({id:this.page, number:this.numberOfPages}).then(()=>{
					this.loading=false
				})
			},
				scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				

				if(user.is_company){
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}	,
		},
		components: {
			AppTabs,
			PulseLoader
		},

		computed: {
			...mapState({
				isNext: state => state.Users.next,
				counts: state => state.Users.count,
				deletedStaff: state => state.Users.deletedStaff
			}
			),
			pageCount(){
				if(this.counts/this.numberOfPages > 1){
					return Math.ceil(this.counts/this.numberOfPages)
				}
				else{
					return null
				}
			},
			...mapGetters('Users', [
        'customerOnly',
        'staffOnly'
      ])
		},

		created() {

			this.emptyState = 'Sorry!, we couldnt find matching results'
			if(this.scopeCheck('view_all_deactivated_users')){
				this.tabList = ["Customers", "Staff", "Requests","Archived"]
			}
			else{
				this.tabList = ["Customers", "Staff","Requests"]
			}
			this.loading=true	
			this.getcustomers({id:this.page, number:this.numberOfPages}).then(()=>{
				this.loading=false
			})
			UsersServices.getEnquiries().then((response)=>{
				this.enquiries = response.data
			})
			this.getstaff()
			this.getDeletedStaff()
			// this.getassessors()

		},

		mounted() {

		}
	}
</script>